var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"#fff","overlay-opacity":"0.8","max-width":"550","persistent":""},on:{"click:outside":_vm.close},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-card',[(_vm.dialogLoading)?_c('SkeletonPreloader',{attrs:{"count":1,"type":'list-item-two-line',"width":'100%',"height":'13vh'}}):[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addUserAccount)}}},[_c('v-card-title',{staticClass:"black--text ft-light text-md"},[_vm._v("Add Default User "),_c('v-btn',{staticClass:"add-payment close-icon",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("close")])])],1),_c('v-card-text',[_c('ValidationProvider',{attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3 ",class:errors.length > 0 ? 'error--text' : 'black--text'},[_vm._v("people")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"error-messages":errors[0],"autofocus":"","hint":"Enter your default user name","label":"Full Name","persistent-hint":true},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required|numeric|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3 ",class:errors.length > 0 ? 'error--text' : 'black--text'},[_vm._v("phone")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"error-messages":errors[0],"hint":"Enter admin's phone number","label":"Phone Number","persistent-hint":true},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3 ",class:errors.length > 0 ? 'error--text' : 'black--text'},[_vm._v("badge")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"error-messages":errors[0],"hint":"Enter your account number","label":"Account Number","persistent-hint":true},model:{value:(_vm.user.accountNumber),callback:function ($$v) {_vm.$set(_vm.user, "accountNumber", $$v)},expression:"user.accountNumber"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-full"},[_c('i',{staticClass:"material-icons-outlined mr-4 mt-3 ",class:errors.length > 0 ? 'error--text' : 'black--text'},[_vm._v("lock")]),_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"error-messages":errors[0],"hint":"Enter admin's PIN code","label":"PIN Code","persistent-hint":true},model:{value:(_vm.user.pin),callback:function ($$v) {_vm.$set(_vm.user, "pin", $$v)},expression:"user.pin"}})],1)]}}],null,true)}),_c('div',{staticClass:"d-flex flex-column flex-grow-1 ml-9"},[_c('h5',{staticClass:"ft font-weight-medium text-sm text-uppercase pt-5 pb-2"},[_vm._v("All Default Users")]),_c('v-data-table',{staticClass:"ft font-weight-medium text-sm",attrs:{"footer-props":{
                  itemsPerPageOptions: [3, 5],
                },"items":_vm.customerDetails.defaultUser.filter(function (user) { return user.name; }),"headers":[
                  { text: 'Name', value: 'name' },
                  { text: 'Phone #', value: 'phone' },
                  ,
                  { text: 'Acc. Status', value: 'isAccountActive' } ]},scopedSlots:_vm._u([{key:"item.isAccountActive",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{staticClass:"vx-shadow",attrs:{"label":"","small":"","color":item.isAccountActive ? '#8bf782c9' : 'rgba(247, 130, 130, 0.79)'}},[_c('span',{staticClass:"ft",style:({ color: item.isAccountActive ? '#108407' : 'rgb(238, 7, 7)' })},[_vm._v(_vm._s(item.isAccountActive ? 'ENABLED' : 'DISABLED'))])])]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-sm ft text-capitalize",attrs:{"text":"","color":"accent"},on:{"click":_vm.close}},[_vm._v("Cancel ")]),_c('v-btn',{staticClass:"ft font-weight-medium text-sm text-capitalize",attrs:{"color":"primary","disabled":_vm.loading,"loading":_vm.loading,"type":"submit"}},[_vm._v("Save")])],1)],1)]}}])})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }