import { render, staticRenderFns } from "./CreditUnionDefaultAdminDialog.vue?vue&type=template&id=2bb98a10&scoped=true&"
import script from "./CreditUnionDefaultAdminDialog.vue?vue&type=script&lang=ts&"
export * from "./CreditUnionDefaultAdminDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bb98a10",
  null
  
)

export default component.exports