


















































































































































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { ICalculatedValues, IDefaultUser, IProduct, IStore, IUser, PayloadState } from '@/types/types';

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { max, numeric, required } from 'vee-validate/dist/rules';
import { debounce } from 'lodash';
extend('required', {
  ...required,
  message: 'Field is required',
});
extend('numeric', {
  ...numeric,
  message: 'Field accept only numbers',
});
extend('max', {
  ...max,
  message: 'Invalid input provided',
});
const productsModule = namespace('products');
const customersModule = namespace('customers');
const authModule = namespace('auth');

@Component({
  name: 'CreditUnionDefaultAdminDialog',
  components: {
    ValidationObserver,
    ValidationProvider,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
})
export default class CreditUnionDefaultAdminDialog extends mixins(WidgetMixins) {
  @Getter('getResetFormState') resetFormState!: boolean;
  @customersModule.Getter('getCustomerDetails') customerDetails!: { defaultUser: Array<IDefaultUser> };
  @authModule.Getter('getUserDetails') userDetails!: IUser & IStore;
  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) link!: string;

  user: IDefaultUser = {
    pin: '',
    accountNumber: '',
    name: '',
    phone: '',
  };

  @Watch('resetFormState')
  onFormChange(payload: boolean) {
    if (payload) {
      this.user = { pin: '', accountNumber: '', name: '', phone: '' } as IDefaultUser;
    } else this.$store.dispatch('resetFormValues', false, { root: true });
  }

  @Emit('addUserAccount')
  addUserAccount() {
    return this.user;
  }

  @Emit('actions')
  close(): PayloadState {
    this.user = { pin: '', accountNumber: '', name: '', phone: '' } as IDefaultUser;
    return {
      idx: 'add',
      state: false,
    };
  }
}
